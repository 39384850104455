// src/Services.jsx
import {
  DynamicFormRounded,
  ManageSearchRounded,
  TerminalRounded,
  VerifiedUserRounded,
} from "@mui/icons-material";
import {
  Box,
  Container,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { styled } from "@mui/system";
import React from "react";
import { useNavigate } from "react-router";

// Create a styled Card component
const StyledCard = styled("div")(({ theme }) => ({
  backgroundColor: "	rgb(0, 255, 255, .02)", // Soft background color with 20% opacity
  boxShadow: `0px 0px 0px 0.95px ${theme.palette.primary.main}1a`,
  borderColor: theme.palette.primary.main,
  borderRadius: "10px",
  transition: "all 0.3s ease-in-out",
  "&:hover": {
    boxShadow: `0px 2px 10px ${theme.palette.primary.main}`,
  },
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  padding: "5%",
  width: "100%",
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  // border: "1px solid",
  borderRadius: "50%",
  backgroundColor: theme.palette.secondary.main,
  //borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(1),
  width: 56,
  height: 56,
}));
function CyberServices() {
  const navigate = useNavigate();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Container>
      <Box sx={{ textAlign: matches ? "left" : "center" }}>
        <Typography variant={matches ? "h3":"h2"}>Cyber Security Services</Typography>
        <br />
        <Typography>
          Our comprehensive cyber security services are designed to safeguard
          against a wide range of threats, ensuring your business remains secure
          and compliant. Explore our services to see how we can help you fortify
          your defenses, respond to incidents swiftly, and stay ahead of cyber
          adversaries.
        </Typography>
      </Box>

      <Grid container spacing={4} mt={4}>
        <Grid item xs={12} sm={6} md={3}>
          <StyledCard>
            <StyledIconButton disabled>
              <VerifiedUserRounded color="primary" />
            </StyledIconButton>
            <Typography variant="h5" component="div">
              Security Audit
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Evaluate and enhance your security posture by identifying
              vulnerabilities and assessing risks.
            </Typography>
          </StyledCard>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StyledCard>
            <StyledIconButton disabled>
              <ManageSearchRounded color="primary" />
            </StyledIconButton>
            <Typography variant="h5" component="div">
              Vulnerability Mgt
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Continuously identify, classify, and address security
              vulnerabilities in your systems to prevent potential exploits.
            </Typography>
          </StyledCard>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StyledCard>
            <StyledIconButton disabled>
              <TerminalRounded color="primary" />
            </StyledIconButton>
            <Typography variant="h5" component="div">
              PenTesting
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Simulate cyber-attacks to test defenses and uncover weaknesses
              with detailed remediation strategies.
            </Typography>
          </StyledCard>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StyledCard>
            <StyledIconButton disabled>
              <DynamicFormRounded color="primary" />
            </StyledIconButton>
            <Typography variant="h5" component="div">
              Incident Response
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Swiftly contain, eradicate, and recover from security incidents to
              minimize damage and downtime.
            </Typography>
          </StyledCard>
        </Grid>
      </Grid>
    </Container>
  );
}

export default CyberServices;
