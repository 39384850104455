import React from 'react';
import ReactDOM from 'react-dom';
import { Container, Typography, Box, Accordion, AccordionSummary, AccordionDetails, Divider } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled, ThemeProvider, createTheme } from '@mui/system';



const faqs = {
  webDevelopment: [
    {
      question: 'What services do you offer?',
      answer: 'We offer a wide range of web design and development services, including e-commerce solutions, business websites, retail POS systems, and more.',
    },
    {
      question: 'How long does it take to complete a project?',
      answer: 'The timeline for a project depends on its complexity and requirements. Typically, a standard website takes 4-6 weeks to complete.',
    },
    {
      question: 'What technologies do you use?',
      answer: 'We use a variety of modern technologies including React, Material-UI, Node.js, and more to build high-quality web solutions.',
    },
  ],
  cyberSecurity: [
    {
      question: 'Do you provide cybersecurity services?',
      answer: 'Yes, we offer comprehensive cybersecurity services to protect your business from online threats and ensure your data is secure.',
    },
    {
      question: 'How do you handle data breaches?',
      answer: 'In the event of a data breach, we have a dedicated team to quickly respond, mitigate the impact, and implement measures to prevent future breaches.',
    },
    {
      question: 'What kind of cybersecurity measures do you implement?',
      answer: 'We implement various cybersecurity measures including firewalls, encryption, intrusion detection systems, and regular security audits.',
    },
  ],
  itSupport: [
    {
      question: 'What IT support services do you offer?',
      answer: 'We offer a range of IT support services including help desk support, network management, and IT consulting.',
    },
    {
      question: 'How do you provide IT support?',
      answer: 'Our IT support is available 24/7 through multiple channels such as phone, email, and remote desktop support.',
    },
    {
      question: 'Can you help with hardware issues?',
      answer: 'Yes, we provide support for both software and hardware issues to ensure your IT infrastructure is running smoothly.',
    },
  ],
};

const StyledContainer = styled(Container)(({ theme }) => ({
  padding: theme.spacing(4),
  backgroundColor: "transparent",
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[0],
  marginTop: theme.spacing(8),
}));

const FAQSection = ({ title, faqs }) => (
  <Box mt={6}>
    <Typography variant="h5" component="h2" gutterBottom>
      {title}
    </Typography>
    <Divider />
    <Box mt={2}>
      {faqs.map((faq, index) => (
        <Accordion key={index}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel${title}-${index}-content`}
            id={`panel${title}-${index}-header`}
          >
            <Typography variant="h6">{faq.question}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body2" color="textSecondary">
              {faq.answer}
            </Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  </Box>
);

const Faqs = () => {
  console.log('Rendering FAQPage...');
  return (
    <StyledContainer>
      <Typography variant="h4" component="h1" gutterBottom>
        Frequently Asked Questions
      </Typography>
      <Typography variant="body1" paragraph>
        Have questions? We have answers. Here are some of the most common questions we receive from our clients.
      </Typography>
      <FAQSection title="Web Development" faqs={faqs.webDevelopment} />
      <FAQSection title="Cyber Security" faqs={faqs.cyberSecurity} />
      <FAQSection title="IT Support" faqs={faqs.itSupport} />
    </StyledContainer>
  );
};

export default Faqs