// src/Services.jsx
import {
  ArrowRightAltRounded,
  DesignServicesRounded,
  DeveloperModeRounded,
  SecurityRounded,
  SupportAgentRounded,
} from "@mui/icons-material";
import {
  Button,
  Container,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { styled } from "@mui/system";
import React from "react";
import { useNavigate } from "react-router";

// Create a styled Card component
const StyledCard = styled("div")(({ theme }) => ({
  backgroundColor: "	rgb(0, 255, 255, .02)", // Soft background color with 20% opacity
  boxShadow: `0px 0px 0px 0.95px ${theme.palette.primary.main}1a`,
  borderColor: theme.palette.primary.main,
  borderRadius: "10px",
  transition: "all 0.3s ease-in-out",
  "&:hover": {
    boxShadow: `0px 2px 10px ${theme.palette.primary.main}`,
  },
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  padding: "5%",
  width: "100%",
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  // border: "1px solid",
  borderRadius: "50%",
  backgroundColor: theme.palette.secondary.main,
  //borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(1),
  width: 56,
  height: 56,
}));
function Services() {
  const navigate = useNavigate();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Container>
      <Grid container spacing={4} mt={4}>
        <Grid item xs={12} sm={6} md={3}>
          <StyledCard>
            <StyledIconButton disabled>
              <DesignServicesRounded color="primary" />
            </StyledIconButton>
            <Typography variant="h5" component="div">
              Web Design
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Creating visually appealing and user-friendly website designs
              tailored to your business needs.
            </Typography>
            <Button
              variant="text"
              sx={{
                textTransform: "none",
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
              onClick={() => navigate("/web-dev")}
            >
              Read More
              <ArrowRightAltRounded />
            </Button>
          </StyledCard>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StyledCard>
            <StyledIconButton disabled>
              <DeveloperModeRounded color="primary" />
            </StyledIconButton>
            <Typography variant="h5" component="div">
              Web Development
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Providing robust web dev to build dynamic and responsive websites
              that meet business requirements.
            </Typography>
            <Button
              variant="text"
              sx={{
                textTransform: "none",
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
              onClick={() => navigate("/web-dev")}
            >
              Read More
              <ArrowRightAltRounded />
            </Button>
          </StyledCard>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StyledCard>
            <StyledIconButton disabled>
              <SecurityRounded color="primary" />
            </StyledIconButton>
            <Typography variant="h5" component="div">
              Cyber Security
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Providing comprehensive cybersecurity solutions to protect your
              digital assets from threats.
            </Typography>
            <Button
              variant="text"
              sx={{
                textTransform: "none",
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
              onClick={() => navigate("/cyber-sec")}
            >
              Read More
              <ArrowRightAltRounded />
            </Button>
          </StyledCard>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StyledCard>
            <StyledIconButton disabled>
              <SupportAgentRounded color="primary" />
            </StyledIconButton>
            <Typography variant="h5" component="div">
              IT Support
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Offering reliable IT support services to ensure your technology
              infrastructure runs smoothly.
            </Typography>
            <Button
              variant="text"
              sx={{
                textTransform: "none",
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
              onClick={() => navigate("/it-support")}
            >
              Read More
              <ArrowRightAltRounded />
            </Button>
          </StyledCard>
        </Grid>
      </Grid>
    </Container>
  );
}

export default Services;
