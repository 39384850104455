import { Box } from "@mui/material";
import React from "react";
import CyberServices from "../components/home/CyberServices";
import Hero from "../components/home/Hero";
import Reviews from "../components/home/Reviews";
import Risk from "../components/home/Industries";
import Services from "../components/home/Services";
import SupportServices from "../components/home/SupportServices";
import WebServices from "../components/home/WebServices";

const Home = () => {
  return (
    <Box sx={{ maxWidth:"95vw"}}>
      <Hero />
      <Box sx={{ display: "flex", flexDirection: "column", gap: "15vh", maxWidth:"95vw" }}>
        <Services />
        <Risk />
        <WebServices />
        <CyberServices />
        <SupportServices />
        <Reviews />
      </Box>
    </Box>
  );
};

export default Home;
