import React from "react";
import ReactDOM from "react-dom";
import {
  Container,
  Typography,
  Box,
  Card,
  CardContent,
  Grid,
} from "@mui/material";
import { styled } from "@mui/system";

// Sample data for company statements and highlights
const statements = [
  {
    id: "1",
    title: "Mission Statement",
    description:
      "At Rowg, our mission is to revolutionize the digital landscape through innovative technology solutions that empower businesses to thrive and individuals to excel. We are committed to delivering exceptional quality, fostering enduring partnerships, and driving positive change in the world.",
  },

  {
    id: "2",
    title: "Vision Statement",
    description:
      "Our vision at Rowg is to be the catalyst for transformative technological advancements, shaping a future where innovation knows no bounds. We aspire to be the premier choice for businesses seeking to leverage technology to achieve their goals, while continuously pushing the boundaries of what's possible.",
  },

];

// Styled components
const StyledContainer = styled(Container)(({ theme }) => ({
  padding: theme.spacing(4),
  backgroundColor: "transparent",
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[0],
  marginTop: theme.spacing(4),
}));

const About = () => {
  return (
    <StyledContainer>
      <Typography variant="h4" component="h1" gutterBottom>
        About Us
      </Typography>
      <Typography variant="body1" paragraph>
        Welcome to Rowg Concepts! We are a leading provider of innovative
        technology solutions, committed to delivering excellence and creating
        value for our clients. Our team of experts is dedicated to pushing the
        boundaries of what's possible, ensuring that we stay at the forefront of
        the tech industry.
      </Typography>
     
      <Box mt={4}>
        <Typography variant="h5" component="h2" gutterBottom>
          Mission and Vision
        </Typography>
        <Grid container spacing={4}>
          {statements.map((statement, index) => (
            <Grid item xs={12} sm={12} md={12} key={index}>
              <Card>
                <CardContent>
                  <Typography variant="h6" component="h3">
                    {statement.id}
                  </Typography>
                  <Typography variant="subtitle1" color="textSecondary">
                    {statement.title}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {statement.description}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
    </StyledContainer>
  );
};

export default About;
