import { Box } from "@mui/material";
import { useState } from "react";
import { Route, Routes } from "react-router";
import "./App.css";
import Footer from "./components/Footer";
import ScrollToTop from "./components/ScrollToTop";
import Navigation from "./components/Navigation";
import About from "./pages/About";
import Contact from "./pages/Contact";
import CyberSec from "./pages/CyberSec";
import Faqs from "./pages/Faqs";
import Home from "./pages/Home";
import ITSupport from "./pages/ITSupport";
import Webdev from "./pages/Webdev";
import React from "react";
import Pricing from "./pages/Pricing";
import NotFound from "./pages/NotFound";

function App() {
  const [count, setCount] = useState(0);

  return (
    <Box className="background">
      <Navigation />
      <Routes>
        <Route path="/" exact element={<Home />} />
        <Route path="/about" exact element={<About />} />
        <Route path="/cyber-sec" exact element={<CyberSec />} />
        <Route path="/web-dev" exact element={<Webdev />} />
        <Route path="/pricing" exact element={<Pricing />} />
        <Route path="/it-support" exact element={<ITSupport />} />
        <Route path="/contact" exact element={<Contact />} />
        <Route path="/faqs" exact element={<Faqs />} />
        <Route path="*" exact element={<NotFound />} />
      </Routes>
      <ScrollToTop />
      <Footer />
    </Box>
  );
}

export default App;
