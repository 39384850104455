import { Container, Button, Typography, Grid, useTheme, useMediaQuery } from "@mui/material";
import React from "react";
import "./home.css";
import king from "./Coding-amico.svg";
import ParticlesAnime from "./ParticlesAnime";
import { useNavigate } from "react-router";

const Hero = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Container
      sx={{
        display: "flex",
        // flexDirection: "column",
        // alignItems: "center",
        minHeight: "78vh",
        // justifyContent: "center",
       width:matches ? "95vw":"100vw",
      
      }}
    >
      <Grid container>
        <Grid
          item
          sm={12}
          md={7}
          xs={12}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "center",  width:matches?"":"100vw",
          }}
        >
          <Typography variant={matches?"h3":"h2"} component="h2" gutterBottom>
            Challenging
          </Typography>
          <Typography variant={matches?"h3":"h2"} component="h2" gutterBottom>
            What's Possible
          </Typography>
          <br/>
          <Typography variant={matches?"body-md":"body-sm"} gutterBottom>
            Driving innovation at the forefront, we transform ideas into
            reality. Empowering individuals and businesses with pioneering
            technology solutions. Together, we are shaping a future where
            possibilities are limitless
          </Typography>
          <br />
          <Button variant="contained" onClick={() => navigate("/contact")}>
            <Typography sx={{ fontWeight: "bold" }}>
              Get Started with Us
            </Typography>
          </Button>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={5}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            src={king}
            alt=""
            style={{ maxHeight: "50vh", maxWidth: "90vw !important" }}
          />
        </Grid>
      </Grid>
      <ParticlesAnime />
    </Container>
  );
};

export default Hero;
