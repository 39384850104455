import { Box, Container, Divider, Typography } from "@mui/material";
import { styled } from "@mui/system";
import React from "react";

const StyledContainer = styled(Container)(({ theme }) => ({
  padding: theme.spacing(4),
  backgroundColor: "transparent",
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[0],
  marginTop: theme.spacing(8),
}));

const ProjectCard = ({ name, description }) => (
  <Box
    mt={2}
    border="1px solid"
    borderColor="primary.main"
    borderRadius={4}
    p={2}
  >
    <Typography variant="h6" gutterBottom color="primary">
      {name}
    </Typography>
    <Typography variant="body1" color="textPrimary">
      {description}
    </Typography>
  </Box>
);

const ITSupport = () => {
  const projects = [
    {
      title: "Enterprise Network Overhaul",
      description:
        "Enhanced infrastructure for a global retail chain, boosting network performance by 40% and reducing downtime by 90%.",
    },
    {
      title: "Cloud Migration for Financial Firm",
      description:
        "Migrated a financial services firm's IT infrastructure to the cloud, achieving 99.9% uptime and reducing costs by 25%.",
    },
    {
      title: "Database Optimization",
      description:
        "Optimized the database performance for an e-commerce company, reducing query times by 50% and improving transaction speed.",
    },
    {
      title: "Desktop Support Transformation",
      description:
        "Revamped desktop support for a law firm, increasing resolution speed by 35% and customer satisfaction by 20%.",
    },
    {
      title: "System Administration Enhancement",
      description:
        "Upgraded system administration processes for a tech startup, improving server uptime to 99.95% and streamlining updates.",
    },
  ];

  return (
    <StyledContainer>
      <Typography variant="h4" component="h1" gutterBottom color="primary">
        IT Support Services
      </Typography>
      <Typography variant="body1" paragraph color="textPrimary">
        At Rowg, we understand that running a business is demanding, and
        technical issues can be a major roadblock to success. That's why we're
        here to provide you with reliable and responsive IT support services
        tailored to meet your unique needs.
      </Typography>
      <Box mt={4}>
        <Typography variant="h5" component="h2" gutterBottom color="primary">
          1. IT Project Coordination
        </Typography>
        <Divider />
        <Typography variant="body1" paragraph color="textPrimary">
          Coordinating and managing IT projects, including planning, scheduling,
          and resource allocation. Communicating project status and updates to
          stakeholders and project team members. Monitoring project progress and
          identifying risks and issues that may impact project delivery.
        </Typography>
      </Box>
      <Box mt={4}>
        <Typography variant="h5" component="h2" gutterBottom color="primary">
          2. Systems Administration
        </Typography>
        <Divider />
        <Typography variant="body1" paragraph color="textPrimary">
          Managing and maintaining server infrastructure, including
          installation, configuration, and maintenance of servers. Monitoring
          server performance and troubleshooting issues related to server
          hardware, operating systems, and applications. Implementing and
          maintaining server security measures, such as access controls and
          security patches.
        </Typography>
      </Box>
      <Box mt={4}>
        <Typography variant="h5" component="h2" gutterBottom color="primary">
          3. Desktop Support
        </Typography>
        <Divider />
        <Typography variant="body1" paragraph color="textPrimary">
          Installing, configuring, and upgrading hardware components and
          software applications on desktop computers. Performing routine
          maintenance tasks, such as updates, patches, and backups, to ensure
          system reliability and security. Troubleshooting and resolving issues
          related to desktop hardware, operating systems, and applications.
        </Typography>
      </Box>
      <Box mt={4}>
        <Typography variant="h5" component="h2" gutterBottom color="primary">
          4. Database Administration
        </Typography>
        <Divider />
        <Typography variant="body1" paragraph color="textPrimary">
          Installing, configuring, and maintaining database management systems
          (DBMS), such as MySQL, Oracle, or SQL Server. Optimizing database
          performance and troubleshooting issues related to database performance
          and scalability. Implementing and maintaining database security
          measures, such as user access controls and encryption.
        </Typography>
      </Box>

      <Box mt={4}>
        <Typography variant="h4" component="h2" gutterBottom color="primary">
          Featured Projects
        </Typography>
        {projects.map((project, index) => (
          <ProjectCard
            key={index}
            name={project.title}
            description={project.description}
          />
        ))}
      </Box>
    </StyledContainer>
  );
};

export default ITSupport;
