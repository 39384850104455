import {
  Card,
  CardMedia,
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
import Support from "../../assets/images/support-image.svg";

const SupportServices = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Container>
      <Typography
        mb={4}
        sx={{ textAlign: matches ? "left" : "center" }}
        variant={matches?"h3":"h2"}
      >
        Tech Support Services
      </Typography>

      <Grid container spacing={4}>
        <Grid item sm={12} md={5} lg={5}>
          <Stack spacing={4}>
            <FormControlLabel
              control={<Checkbox checked />}
              label="Help Desk Support: Providing timely assistance and troubleshooting"
            />
            <FormControlLabel
              control={<Checkbox checked />}
              label="System Maintenance: Regular maintenance and updates to ensure system reliability"
            />
            <FormControlLabel
              control={<Checkbox checked />}
              label="Network Management: Monitoring and managing network infrastructure for optimal performance"
            />
            <FormControlLabel
              control={<Checkbox checked />}
              label="Data Backup and Recovery: Secure backup solutions and swift recovery in occurence of data loss"
            />
            <FormControlLabel
              control={<Checkbox checked />}
              label="User Training: Training and educating users on best practices for organization's IT security"
            />
          </Stack>
        </Grid>
        <Grid item sm={12} md={7} lg={7}>
          <Typography mb={4}>
            Our IT support services are aimed at keeping your business
            operations running smoothly and efficiently. We provide help desk
            support, offering timely assistance and troubleshooting to resolve
            any issues. Our team provides regular maintenance and updates to
            your systems, guaranteeing reliability and security.
          </Typography>
          <Card>
            <CardMedia
              component="img"
              alt="Cyber Security Services"
              height="300"
              image={Support}
              title="Cyber Security Services"
              sx={{ maxWidth: "95vw", objectFit: "cover" }}
            />
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default SupportServices;
