import React, { useState } from "react";
import ReactDOM from "react-dom";
import {
  Container,
  Typography,
  TextField,
  Button,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Box,
} from "@mui/material";
import { styled } from "@mui/system";
import axios from "axios";

const StyledContainer = styled(Container)(({ theme }) => ({
  padding: theme.spacing(4),
  backgroundColor: "transparent",
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[0],
  marginTop: theme.spacing(4),
}));

const ContactForm = styled("form")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(2),
}));

function Contact() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    service: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    //console.log('Form Data:', formData);
    // Handle form submission logic
    try {
      await axios.post(`https://rowg.co.ke/api/contact/`, {
        formData,
      });
      setFormData({ name: "", email: "", service: "", message: "" });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <StyledContainer>
      <Typography variant="h4" component="h1" gutterBottom>
        Contact Us
      </Typography>
      <ContactForm onSubmit={handleSubmit}>
        <TextField
          label="Name"
          variant="outlined"
          name="name"
          value={formData.name}
          onChange={handleChange}
          required
        />
        <TextField
          label="Email"
          variant="outlined"
          type="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          required
        />
        <FormControl variant="outlined" required>
          <InputLabel>Service Type</InputLabel>
          <Select
            label="Service Type"
            name="service"
            value={formData.service}
            onChange={handleChange}
          >
            <MenuItem value="Web Development">Web Development</MenuItem>
            <MenuItem value="Cyber Security">Cyber Security</MenuItem>
            <MenuItem value="Tech Support">Tech Support</MenuItem>
          </Select>
        </FormControl>
        <TextField
          label="Message"
          variant="outlined"
          name="message"
          value={formData.message}
          onChange={handleChange}
          multiline
          rows={4}
          required
        />
        <Button type="submit" variant="contained" color="primary">
          Submit
        </Button>
      </ContactForm>
    </StyledContainer>
  );
}

export default Contact;
