import {
    Avatar,
    Box,
    Button,
    Card,
    CardContent,
    CardMedia,
    Container,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
  } from "@mui/material";
  import React from 'react'

const pricingData = [
    {
      task: "Website Design",
      description:
        "Custom design and layout creation tailored to the client's branding and preferences.",
      price: "Starting from $50",
    },
    {
      task: "Frontend Development",
      description:
        "Implementation of the website design using HTML, CSS, and JavaScript to create a responsive layout.",
      price: "Starting from $20 per page",
    },
    {
      task: "Backend Development",
      description:
        "Development of server-side logic, database integration, and application functionality.",
      price: "Starting from $100 per feature",
    },
    {
      task: "E-commerce Integration",
      description:
        "Integration of e-commerce functionality, including shopping carts, payment gateways, and inventory management.",
      price: "Starting from $60",
    },
    {
      task: "Content Management System (CMS)",
      description:
        "Implementation of a CMS for easy content management and updates by the client.",
      price: "Starting from $50",
    },
    {
      task: "Search Engine Optimization (SEO)",
      description:
        "Optimization of website content, meta tags, and structure to improve search engine rankings.",
      price: "Starting from $30",
    },
    {
      task: "Maintenance and Support",
      description:
        "Ongoing maintenance, security updates, and technical support to ensure the website's functionality.",
      price: "Starting from $100 per month",
    },
    {
      task: "Custom Features",
      description:
        "Development of custom features or functionalities specific to the client's requirements.",
      price: "Price upon request",
    },
  ];
  
const Pricing = () => {
  return (
    <Container><Box mt={4}>
    <Typography variant="h4" gutterBottom>
      Web Development Pricing
    </Typography>
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Task</TableCell>
            <TableCell>Description</TableCell>
            <TableCell>Price</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {pricingData.map((row) => (
            <TableRow key={row.task}>
              <TableCell component="th" scope="row">
                {row.task}
              </TableCell>
              <TableCell>{row.description}</TableCell>
              <TableCell>{row.price}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  </Box></Container>
  )
}

export default Pricing