// src/Footer.jsx
import {
  Box,
  Container,
  Divider,
  Grid,
  Link,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import { Navigate, useNavigate } from "react-router";

function Footer() {
  const navigate = useNavigate();
  return (
    <footer>
      <Container>
        {/* First Row */}
        <Grid
          container
          spacing={3}
          mt={4}
          justifyContent="space-around"
          alignItems="flex-start"
        >
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" gutterBottom>
              ROWG
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Empowering innovation through technology. Committed to exceptional
              solutions and lasting partnerships
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" gutterBottom>
              Links
            </Typography>
            <Stack
              direction="row"
              divider={<Divider orientation="vertical" flexItem />}
              spacing={2}
            >
              <Box>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{ cursor: "pointer" }}
                  onClick={() => navigate("/web-dev")}
                >
                  Web Dev
                </Typography>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{ cursor: "pointer" }}
                  onClick={() => navigate("/it-support")}
                >
                  Tech Support
                </Typography>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{ cursor: "pointer" }}
                  onClick={() => navigate("/cyber-sec")}
                >
                  Cyber Sec
                </Typography>
              </Box>
              <Box>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{ cursor: "pointer" }}
                  onClick={() => navigate("/")}
                >
                  Home
                </Typography>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{ cursor: "pointer" }}
                  onClick={() => navigate("/about")}
                >
                  About
                </Typography>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{ cursor: "pointer" }}
                  onClick={() => navigate("/faqs")}
                >
                  FAQs
                </Typography></Box>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{ cursor: "pointer" }}
                  onClick={() => navigate("/contact")}
                >
                  Contact
                </Typography>
            </Stack>
              
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" gutterBottom>
              Contact Us
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Email: <>rowgconcepts@gmail.com</>
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Tel: +254705624743
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Address: Summit House, Moi Avenue
            </Typography>
          </Grid>
        </Grid>

        {/* Second Row */}
        <Grid container spacing={3} mt={4}>
          <Grid item xs={12}>
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{ textAlign: "center" }}
            >
              {"©"}
              {new Date().getFullYear()}
              {" All rights reserved - Rowg"}
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </footer>
  );
}

export default Footer;
