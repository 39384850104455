import {
  Box,
  Card,
  CardMedia,
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
import Website from "../../assets/images/website-image.svg"

const WebServices = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Container>
      <Typography mb={4} sx={{ textAlign: matches ? "left":"center" }} variant={matches ? "h3":"h2"}>
        Web Dev Services
      </Typography>

      <Grid container spacing={4}>
        <Grid item sm={12} md={5} lg={5}>
          <Typography gutterBottom variant="h5">
            Our Web Development Process
          </Typography>
          <Stack spacing={4}>
            <FormControlLabel
              control={<Checkbox checked />}
              label="Requirement Analysis: Understanding client needs and goals"
            />
            <FormControlLabel
              control={<Checkbox checked />}
              label="Design Phase: Crafting user-friendly designs reflecting the client's brand"
            />
            <FormControlLabel
              control={<Checkbox checked />}
              label="Development: Coding the website with modern technologies"
            />
            <FormControlLabel
              control={<Checkbox checked />}
              label="Testing: Identifying and fixing issues for a smooth experience"
            />
            <FormControlLabel
              control={<Checkbox checked />}
              label="Deployment and Maintenance: Launching and providing ongoing support"
            />
          </Stack>
        </Grid>
        <Grid item sm={12} md={7} lg={7}>
          <Typography mb={4}>
            Our web design and development services are aimed at creating
            beautiful, user-friendly, and high-performing websites. We
            prioritize responsive design to ensure your site looks great on all
            devices, and our focus on UX guarantees a seamless and enjoyable
            user experience.
          </Typography>
          <Card>
            <CardMedia
              component="img"
              alt="Web Design and Development"
              height="300"
              image={Website}// Replace with your actual image URL
              title="Web Design and Development"
              sx={{ maxWidth: "95vw", objectFit: "cover" }}
            />
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default WebServices;
