// src/Reviews.jsx
import React from "react";
import {
  Box,
  Card,
  CardContent,
  Container,
  Grid,
  Rating,
  Typography,
} from "@mui/material";
import { styled } from "@mui/system";

// Create a styled Card component with gradient borders
const StyledCard = styled("div")(({ theme }) => ({
  backgroundColor: "rgb(0, 255, 255, .02)", // Soft background color with 20% opacity
  borderRadius: "10px",
  position: "relative",
  overflow: "hidden",
  transition: "all 0.3s ease-in-out",
  "&::before": {
    content: '""',
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    borderRadius: "10px",
    padding: "1px", // Adjusted to make the border very thin
    background: `linear-gradient(135deg, ${theme.palette.primary.main} 0%, ${theme.palette.primary.main}20 25%, ${theme.palette.primary.main}00 50%, ${theme.palette.primary.main}20 75%, ${theme.palette.primary.main} 100%)`,
    WebkitMask:
      "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
    mask: "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
    maskComposite: "exclude",
    WebkitMaskComposite: "xor",
    zIndex: 1,
  },
  "&:hover": {
    boxShadow: `0px 4px 20px ${theme.palette.primary.main}`,
  },
}));

// Create a styled CardContent component to align items at the start
const StyledCardContent = styled(CardContent)({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  position: "relative",
  gap: 1,
});

function Reviews() {
  return (
    <Container>
      <Box sx={{ textAlign: "center" }}>
        <Typography variant="h2">Reviews by Clients</Typography>
        <br />
        <Typography sx={{ maxWidth: 1000 }}>
          Discover what others are saying about our tech firm's services. Dive
          into our reviews section to see firsthand experiences and testimonials
          from our valued clients and partners.
        </Typography>
      </Box>
      <Grid container spacing={4} mt={4}>
        <Grid item xs={12} sm={6} md={3}>
          <StyledCard>
            <StyledCardContent>
              <Typography variant="h5" component="div">
                Mr. Kwame
              </Typography>
              <Rating name="half-rating" defaultValue={5} precision={0.5} />

              <Typography variant="body2" color="text.secondary">
                Fantastic experience working with them. They understood our
                requirements and executed them perfectly.
              </Typography>
            </StyledCardContent>
          </StyledCard>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StyledCard>
            <StyledCardContent>
              <Typography variant="h5" component="div">
                Ishtyak Ahmed
              </Typography>
              <Rating name="half-rating" defaultValue={4.5} precision={0.5} />

              <Typography variant="body2" color="text.secondary">
                Excellent service and support. Highly recommend for their
                professional approach and quality work.
              </Typography>
            </StyledCardContent>
          </StyledCard>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StyledCard>
            <StyledCardContent>
              <Typography variant="h5" component="div">
                Primers Bookstore
              </Typography>
              <Rating name="half-rating" defaultValue={5} precision={0.5} />

              <Typography variant="body2" color="text.secondary">
                The team was very responsive and delivered our project on time
                with great quality. Highly Recommended.
              </Typography>
            </StyledCardContent>
          </StyledCard>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StyledCard>
            <StyledCardContent>
              <Typography variant="h5" component="div">
                Crystal Clinic
              </Typography>
              <Rating name="half-rating" defaultValue={5} precision={0.5} />

              <Typography variant="body2" color="text.secondary">
                Very knowledgeable and helpful team. Our go-to company for all
                of our IT support and development needs.
              </Typography>
            </StyledCardContent>
          </StyledCard>
        </Grid>
      </Grid>
    </Container>
  );
}

export default Reviews;
