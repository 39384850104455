// src/main.jsx
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { BrowserRouter } from 'react-router-dom';
import TechMono from './assets/fonts/ttf/share-tech-mono-latin-400-normal.ttf';
import ScrollToTop from './components/ScrollToTop';



const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#00FFFF',  // Cyan
    },
    secondary: {
      main: '#324B4B',  // Dark teal
    },
    info: {
      main: '#95B1B0',  // Light teal
    },
    success: {
      main: '#ADC5FF',  // Light blue
    },
    warning: {
      main: '#7390E9',  // Medium blue
    },
  },
  typography: {
    fontFamily: 'TechMono, Arial', // Use Roboto as the default font
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'TechMono';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local('TechMono'), local('TechMono-Regular'), url(${TechMono}) format('woff2');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
      `,
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <ScrollToTop/>
      <App />
    </ThemeProvider></BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);