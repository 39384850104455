import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import MenuIcon from "@mui/icons-material/Menu";
import {
  AppBar,
  Box,
  Button,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Toolbar,
  useMediaQuery,
} from "@mui/material";
import { styled, useTheme } from "@mui/system";
import React, { useState } from "react";
import { useNavigate } from "react-router";
import Logo from "../assets/images/rowg.png";
import {
  ArrowDropDownRounded,
  CallRounded,
  MailRounded,
  WhatsApp,
} from "@mui/icons-material";

// Styled components
const StyledButton = styled(Button)(({ theme }) => ({
  color: `${theme.palette.primary.main}`,
  textTransform: "none", // Make the button look like text
  fontSize: "16px",
}));

// Styled IconButton with very thin border
const StyledIconButton = styled(IconButton)(({ theme }) => ({
  borderWidth: "0.5px", // Very thin border
  borderStyle: "solid",
  borderColor: `${theme.palette.primary.main}`,
  padding: "4px", // Extra small size
  "& svg": {
    fontSize: "16px", // Small icon size
  },
}));

const StyledMenu = styled(Menu)({
  marginTop: "35px",
});

function Navigation() {
  const [anchorEl, setAnchorEl] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const navigate = useNavigate();

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const drawerList = (
    <Box
      sx={{ width: 250 }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        <ListItem
          onClick={() => {
            navigate("/web-dev");
            handleMenuClose();
          }}
        >
          <ListItemText primary="Web Dev" />
        </ListItem>
        <ListItem
          onClick={() => {
            navigate("/cyber-sec");
            handleMenuClose();
          }}
        >
          <ListItemText primary="Cyber Sec" />
        </ListItem>
        <ListItem
          onClick={() => {
            navigate("/it-support");
            handleMenuClose();
          }}
        >
          <ListItemText primary="Tech Support" />
        </ListItem>
        <ListItem
          onClick={() => {
            navigate("/pricing");
            handleMenuClose();
          }}
        >
          <ListItemText primary="Pricing" />
        </ListItem>
        <ListItem
          onClick={() => {
            navigate("/about");
            handleMenuClose();
          }}
        >
          <ListItemText primary="About" />
        </ListItem>
        <ListItem
          onClick={() => {
            navigate("/contact");
            handleMenuClose();
          }}
        >
          <ListItemText primary="Contact" />
        </ListItem>
      </List>
    </Box>
  );

  return (
    <AppBar position="sticky" sx={{ backgroundColor: "black" }}>
      <Toolbar sx={{ backgroundColor: "black" }}>
        {/* Company Name */}
        <Box sx={{ flexGrow: isMobile ? 1 : 0 }}>
          <img
            src={Logo}
            style={{ width: "5rem", cursor: "pointer" }}
            onClick={() => navigate("/")}
          />
        </Box>

        {isMobile ? (
          <>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={toggleDrawer(true)}
            >
              <MenuIcon />
            </IconButton>
            <Drawer
              anchor="right"
              open={drawerOpen}
              onClose={toggleDrawer(false)}
            >
              {drawerList}
            </Drawer>
          </>
        ) : (
          <>
            <Box
              sx={{ flexGrow: 1, display: "flex", justifyContent: "center" }}
            >
              <StyledButton
                onClick={handleClick}
                endIcon={
                  <ArrowDropDownRounded
                    style={{
                      transform: open ? "rotate(180deg)" : "rotate(0deg)",
                    }}
                  />
                }
              >
                Services
              </StyledButton>
              <StyledButton onClick={() => navigate("/pricing")}>
                Pricing
              </StyledButton>
              <StyledButton onClick={() => navigate("/about")}>
                About
              </StyledButton>
              <StyledButton onClick={() => navigate("/contact")}>
                Contact
              </StyledButton>
              <StyledMenu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
                MenuListProps={{ onMouseLeave: handleMenuClose }}
              >
                <MenuItem
                  onClick={() => {
                    handleMenuClose();
                    navigate("/web-dev");
                  }}
                >
                  Web Development
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleMenuClose();
                    navigate("/cyber-sec");
                  }}
                >
                  Cyber Security
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleMenuClose();
                    navigate("/it-support");
                  }}
                >
                  IT Support
                </MenuItem>
              </StyledMenu>
            </Box>

            {/* Social Media Icons */}
            <Stack direction="row" spacing={2} sx={{ flexGrow: 0, padding: 0 }}>
              <StyledIconButton
                color="primary"
                aria-label="whatsapp"
                onClick={() => window.open("https://wa.link/vq4fo5")}
              >
                <WhatsApp />
              </StyledIconButton>
              <StyledIconButton
                color="primary"
                aria-label="mail"
                component="a"
                href="mailto:rowgconcepts@gmail.com"
              >
                <MailRounded />
              </StyledIconButton>
              <StyledIconButton
                color="primary"
                aria-label="call"
                component="a"
                href="tel:2348101234567"
              >
                <CallRounded />
              </StyledIconButton>
            </Stack>
          </>
        )}
      </Toolbar>
    </AppBar>
  );
}

export default Navigation;
