// src/App.jsx
import React from "react";
import {
  Box,
  Card,
  CardContent,
  Container,
  Grid,
  Typography,
  useTheme,useMediaQuery
} from "@mui/material";
import { styled } from "@mui/system";

// Create a styled Card component
const StyledCard = styled("div")(({ theme }) => ({
  backgroundColor: "	rgb(0, 255, 255, .02)", // Soft background color with 20% opacity
  border: "1px solid transparent",
  transition: "all 0.3s ease-in-out",
  borderRadius: "10px",
  "&:hover": {
    border: `.2px solid ${theme.palette.primary.main}`,
    cursor: "pointer",

    boxShadow: `0px .2px 2.5px ${theme.palette.primary.main}`,
  },
  padding: "5%",
}));

// Create a styled CardContent component to align items at the start
const StyledCardContent = styled(CardContent)({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
});

function Industries() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Container>
      <Box sx={{ textAlign: matches ? "left":"center" }}>
        <Typography variant={matches?"h3":"h2"}>Service by Industry</Typography>
        <br />
        <Typography sx={{}}>
          We offer a wide range of services tailored to various industries.
          Explore our services to find out how we can help your business thrive
          in the digital age.
        </Typography>
      </Box>
      <Grid container spacing={4} mt={4}>
        <Grid item xs={12} sm={6} md={4}>
          <StyledCard sx={matches && { border: `.2px solid #00FFFF` }}>
            <Typography variant="h5" component="div">
              Health Care
            </Typography>
            <Typography variant="body2" color="text.secondary">
              We offer comprehensive IT solutions for the healthcare industry,
              ensuring secure and efficient management of patient data and
              healthcare operations
            </Typography>
          </StyledCard>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StyledCard sx={{ border: `.2px solid #00FFFF` }}>
            <Typography variant="h5" component="div">
              Finance
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Our financial IT services include secure transaction processing,
              data encryption, and compliance with industry regulations to
              protect financial data.
            </Typography>
          </StyledCard>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StyledCard sx={matches && { border: `.2px solid #00FFFF` }}>
            <Typography variant="h5" component="div">
              Retail
            </Typography>
            <Typography variant="body2" color="text.secondary">
              We provide retail IT solutions that enhance customer experiences
              through point-of-sale systems, inventory management, and
              e-commerce platforms.
            </Typography>
          </StyledCard>
        </Grid>
      </Grid>
    </Container>
  );
}

export default Industries;
