import React from "react";
import ReactDOM from "react-dom";
import {
  Container,
  Typography,
  Box,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Button,
  Avatar,
  Chip,
} from "@mui/material";
import { styled } from "@mui/system";

import Blue from "../assets/badges/blue.svg";
import Hash from "../assets/badges/hashcracker.svg";
import Ice from "../assets/badges/ice.svg";
import Secintro from "../assets/badges/intro.svg";
import King from "../assets/badges/king.svg";
import Metasploit from "../assets/badges/metasploit.svg";
import Mrrobot from "../assets/badges/mrrobot.svg";
import Ohsint from "../assets/badges/ohsint.svg";
import Streak7 from "../assets/badges/streak7.svg";

// Sample data for projects
const projects = [
  {
    title: "Metasploit",
    description: "Contains te knowledge to use metasploit.",
    image: Metasploit,
  },
  {
    title: "OhSint",
    description: "Having completed the OhSint room.",
    image: Ohsint,
  },
  {
    title: "Blue",
    description: "Hacking into windows via eternal blue.",
    image: Blue,
  },
  {
    title: "Ice",
    description: "Exploiting widows via media server.",
    image: Ice,
  },
  {
    title: "Hash Cracker",
    description: "Cracking all those hashes.",
    image: Hash,
  },
  {
    title: "Security Engineer",
    description: "Complete the security Engineering intro room.",
    image: Secintro,
  },
  {
    title: "King",
    description: "Becoming king of the hill winner.",
    image: King,
  },
  {
    title: "Mr Robot",
    description: "Completing the Mr. Robot room.",
    image: Mrrobot,
  },
  {
    title: "7 Day Streak",
    description: "Achieving a 7 day hacking streak",
    image: Streak7,
  },
];

// Sample data for website types
const websiteTypes = [
  {
    title: "Blogs",
    description:
      "We develop engaging and SEO-friendly blogs that help you share your stories and insights with the world.",
    image: "https://via.placeholder.com/300",
  },
  {
    title: "E-commerce",
    description:
      "Our e-commerce solutions provide seamless shopping experiences, secure transactions, and easy management.",
    image: "https://via.placeholder.com/300",
  },
  {
    title: "Retail POS",
    description:
      "Our retail POS systems streamline your sales processes, inventory management, and customer interactions.",
    image: "https://via.placeholder.com/300",
  },
  {
    title: "Business Websites",
    description:
      "We create professional business websites that enhance your online presence and drive business growth.",
    image: "https://via.placeholder.com/300",
  },
];

// Styled components
const StyledContainer = styled(Container)(({ theme }) => ({
  padding: theme.spacing(4),
  backgroundColor: "transparent",
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[0],
  marginTop: theme.spacing(4),
}));

const StyledCard = styled(Card)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[1],
  "&:hover": {
    boxShadow: `0px 2px 10px ${theme.palette.primary.main}`,
  },
}));

const CyberSec = () => {
  return (
    <StyledContainer>
      <Typography variant="h4" component="h1" gutterBottom>
        Cyber Security
      </Typography>
      <Typography variant="body1" paragraph mt={4}>
        Welcome to our cybersecurity portfolio, where we demonstrate my hands-on
        experience and practical skills in the field of cybersecurity. As part
        of my continuous learning journey, we actively engage in various
        challenges on platforms like TryHackMe to sharpen our abilities and stay
        up-to-date with the latest cybersecurity techniques.
      </Typography>
          <Box mt={8}>
        
        <Grid container spacing={4}>
          {projects.map((project, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <StyledCard>
                <CardContent
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  <Avatar
                    src={project.image}
                    sx={{ width: 100, height: 100 }}
                  />
                  <Chip
                    label="rOwg"
                    color="success"
                    variant="outlined"
                    size="small"
                    sx={{ maxWidth: "fit-content" }}
                  />
                  <br />
                  <Typography level="title-lg">{project.title}</Typography>
                  <Typography variant="body-2" sx={{ maxWidth: "24ch" }}>
                    {project.description}
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      gap: 2,
                      mt: 2,
                      "& > button": { borderRadius: "2rem" },
                    }}
                  >
                    <Button
                      variant="text"
                      color="primary"
                      component="a"
                      target="_blank"
                      href="https://tryhackme.com/p/r0wg"
                    >
                      View
                    </Button>
                  </Box>
                </CardContent>
              </StyledCard>
            </Grid>
          ))}
        </Grid>
      </Box>
    </StyledContainer>
  );
};

export default CyberSec;
