import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Container,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { styled } from "@mui/system";
import React from "react";

import { useNavigate } from "react-router";
import blog from "../assets/websites/blog-icon.svg";
import retail from "../assets/websites/cashier-icon.svg";
import forum from "../assets/websites/community-forum.svg";
import commerce from "../assets/websites/global-market-icon.svg";
import business from "../assets/websites/laptop-report-icon.svg";

import retailSample from "../assets/samples/retail.png";
import wix from "../assets/samples/wix.png";
import clinic from "../assets/samples/clinic.png";
import shop from "../assets/samples/shop.png";
import sycom from "../assets/samples/sycom.png";
import copy from "../assets/samples/copy.png";

const projects = [
  {
    title: "Document Validation",
    description:
      "Professional online presence for a company, showcasing services, and essential information.",
    image: copy,
  },
  {
    title: "Commerce Shop",
    description:
      "A responsive e-commerce platform that provides seamless shopping experiences on all devices.",
    image: shop,
  },
  {
    title: "Clinic Website",
    description:
      "User-friendly clinic website featuring appointment booking, doctor profiles, services, and patient resources.",
    image: clinic,
  },
  {
    title: "Retail System",
    description:
      "Online retail system offering seamless shopping, secure payments, inventory management, and customer support.",
    image: retailSample,
  },
  {
    title: "Wix Site",
    description:
      "Professional Wix site showcasing services, client testimonials, contact information, and online bookings.",
    image: wix,
  },
  {
    title: "Project Sycom",
    description:
      "An educational platform that offers interactive learning experiences and comprehensive resources.",
    image: sycom,
  },
];

// Sample data for website types
const websiteTypes = [
  {
    title: "Blogging Sites",
    description:
      "Platform for creating and sharing content, or posts, with a focus on written or multimedia story telling.",
    image: blog,
  },
  {
    title: "E-commerce Shop",
    description:
      "Online platform for selling of products or services, including secure payment options.",
    image: commerce,
  },
  {
    title: "Retail POS",
    description:
      "System for processing retail transactions, managing inventory, and seamless in-store",
    image: retail,
  },
  {
    title: "Business Websites",
    description:
      "Professional online presence for a company, showcasing services, and essential information.",
    image: business,
  },
  {
    title: "Community Forums",
    description:
      "Platforms for users to engage in discussions, and share information on specific interests",
    image: forum,
  },
  {
    title: "Educational Sites",
    description:
      "Designed for learning purposes; Offer courses, and interactive materials to help acquire knowledge.",
    image: business,
  },
];

// Styled components
const StyledContainer = styled(Container)(({ theme }) => ({
  padding: theme.spacing(4),
  backgroundColor: "transparent",
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[0],
  marginTop: theme.spacing(4),
}));

const StyledCard = styled(Card)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[1],
  "&:hover": {
    boxShadow: `0px 4px 20px ${theme.palette.primary.main}`,
  },
}));

const Webdev = () => {
  const navigate = useNavigate();
  return (
    <StyledContainer>
      <Box>
        <Typography variant="h5" component="h2" gutterBottom>
          Types of Websites We Develop
        </Typography>
        <Typography variant="body1" paragraph>
          At Rowg, we offer tailored web development solutions to help your
          business thrive in the digital landscape. Our team of experienced
          developers combines creativity with technical expertise to create
          responsive, user-friendly, and visually engaging websites.
        </Typography>
        <Box mt={4}>
          <Grid container spacing={4}>
            {websiteTypes.map((type, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <StyledCard>
                  <CardContent
                    sx={{
                      alignItems: "center",
                      textAlign: "center",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      gap: "2rem",
                    }}
                  >
                    <Avatar
                      src={type.image}
                      sx={{ width: 56, height: 56, objectFit: "fit" }}
                    />
                    <Typography variant="h6">{type.title}</Typography>
                    <Typography variant="body1" sx={{ maxWidth: "27ch" }}>
                      {type.description}
                    </Typography>
                    <Button onClick={() => navigate("/contact")}>
                      Get Quote
                    </Button>
                  </CardContent>
                </StyledCard>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
      
      <Box mt={8}>
        <Typography variant="h4" component="h1" gutterBottom>
          Web Design and Development Portfolio
        </Typography>
        <Typography variant="body1" paragraph>
          Explore our portfolio of completed projects, showcasing our expertise
          in web design and development. Each project highlights our commitment
          to quality, innovation, and user-centric design.
        </Typography>
        <Grid container spacing={4}>
          {projects.map((project, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <StyledCard>
                <CardMedia
                  component="img"
                  height="180"
                  image={project.image}
                  alt={project.title}
                  sx={{ objectFit: "cover" }}
                />
                <CardContent>
                  <Typography variant="h6" component="h3">
                    {project.title}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {project.description}
                  </Typography>
                </CardContent>
              </StyledCard>
            </Grid>
          ))}
        </Grid>
      </Box>
    </StyledContainer>
  );
};

export default Webdev;
