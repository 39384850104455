import React from 'react';
import { Container, Typography, Button, Box } from '@mui/material';

const NotFound = () => {
  return (
    <Container className="container">
    <Typography variant="h1" component="h1" color="white" className="title">
      404
    </Typography>
    <Typography variant="h4" component="h2" color="white" className="subtitle">
      Page Not Found
    </Typography>
    <Typography variant="body1" color="white" className="description">
      Sorry, the page you are looking for does not exist. You can always go back to the homepage.
    </Typography>
    <Button
      variant="contained"
      color="primary"
      href="/"
      className="button"
      sx={{ bgcolor: 'cyan.dark' }}
    >
      Go to Homepage
    </Button>
  </Container>
  );
};

export default NotFound;
